$COL1: rgba(0,0,0,0); //
$COLFLAT1: #808080; //
$COL2: #ffffff; //
$COLFLAT2: #ffffff; //
$COL3: #1b1b1b; //
$COLFLAT3: #1b1b1b; //
$COL4: #1b1b1b; //
$COLFLAT4: #1b1b1b; //
$COL5: #ffffff; //
$COLFLAT5: #ffffff; //
$COL6: #43a047; //
$COLFLAT6: #43a047; //
$COL7: #ffffff; //
$COLFLAT7: #ffffff; //
$COL8: #ffffff; //
$COLFLAT8: #ffffff; //
$COL9: #f2f2f2; //
$COLFLAT9: #f2f2f2; //
$COL10: #f2f2f2; //
$COLFLAT10: #f2f2f2; //
$COL11: #e8e8e8; //
$COLFLAT11: #e8e8e8; //
$COL12: #ce2d4f; //
$COLFLAT12: #ce2d4f; //
$COL13: #ffffff; //
$COLFLAT13: #ffffff; //
$COL14: #1b1b1b; //
$COLFLAT14: #1b1b1b; //
$COL15: #999999; //
$COLFLAT15: #999999; //
$COL16: #999999; //Grey
$COLFLAT16: #999999; //Grey
$COL17: #009cde; //PayPal Blue
$COLFLAT17: #009cde; //PayPal Blue
$COL18: rgba(255,255,255,0.49); //White trans
$COLFLAT18: #bebebe; //White trans
$COL19: #444444; //Dark Grey
$COLFLAT19: #444444; //Dark Grey
$COL20: #bdbdbd; //Light Grey
$COLFLAT20: #bdbdbd; //Light Grey
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
